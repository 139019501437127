import th from '~/i18n/locales/th.json'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'th',
  fallbackLocale: 'th',
  messages: {
    th,
  },
}))
