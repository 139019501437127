
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as AboutMelZPCJRINMjMeta } from "/vercel/path0/pages/AboutMe.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as MyDeviceandDrumgaMWYpgwgNMeta } from "/vercel/path0/pages/MyDeviceandDrum.vue?macro=true";
import { default as ProjectsGbNA0IbdR4Meta } from "/vercel/path0/pages/Projects.vue?macro=true";
export default [
  {
    name: "AboutMe",
    path: "/AboutMe",
    component: () => import("/vercel/path0/pages/AboutMe.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "MyDeviceandDrum",
    path: "/MyDeviceandDrum",
    component: () => import("/vercel/path0/pages/MyDeviceandDrum.vue")
  },
  {
    name: "Projects",
    path: "/Projects",
    component: () => import("/vercel/path0/pages/Projects.vue")
  }
]